<template>
  <tbody>
    <!-- <tableRow
      v-for="(row, rowindex) in table['data']"
      :key="rowindex"
      :result="result"
      :row="row"
      :currentTable="table"
    ></tableRow>-->

    <tableRow
      v-for="(row, rowindex) in localItems"
      :key="rowindex"
      :currentTable="currentTable"
      :row="row"
      :result="result"
      :rowindex="rowindex"
      @selected="updateSelectedRows(row,...arguments)"
      :selected="checkSelected(row)"
      v-on="$listeners"
      @item-clicked="emitItem(rowindex,row,...arguments)"
    ></tableRow>
  </tbody>
</template>
<script>
import tableRow from "@/commonComponents/tableRow.vue";
export default {
  components: { tableRow },
  computed: {
    localItems() {
      let localItems = this.items;
       if (typeof this.items == "undefined") {
        localItems = this.currentTable.data;
      }
      return localItems;
    },
  },
  props: ["currentTable", "result", "items", "selectedRows"],
  methods: {
    emitItem(rowindex, row, actionName) {
      this.$emit("row-item-clicked", rowindex, row, actionName);
    },
    updateSelectedRows(item, value) {
      this.$emit("selected", item, value);
    },
    checkSelected(item) {
      let selected = false;
      if (this.selectedRows.includes(item.n_id)) {
        selected = true;
      }
      return selected;
    },
  },
};
</script>
