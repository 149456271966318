<template>
  <div>
    <div class=" " :id="ocsTableInfo.ocsTableId">
      <!-- {$id_class= $outKeyLC|cat:"_"|cat:rand ( 0 , 10000 )} -->
      <div
        v-for="(rowLinesLC, outKeyLC) in ocsTableInfo.tablesData"
        :key="outKeyLC"
        class="mb-2"
      >
        <ocsInnerTable
          v-if="filter == 'all' || filter == outKeyLC"
          :table="rowLinesLC"
          :result="result"
          :search="search"
          :outKeyLC="outKeyLC"
        >
        </ocsInnerTable>
      </div>

      <!-- <div class="noDataInfo">{{languagePack('info_No_dat')}}</div> -->
      <div id="bottomControls" class="bottomControls">
        <div class="hrLine1"></div>
        <tableBottomControls
          :table="result.tablesInfo"
          @all-selected="toggleSelectAll(...arguments)"
          @delete-selected="deleteSelected()"
          :allSelected="allSelected"
        ></tableBottomControls>
        <div v-for="(deleteArray, key) in deletedObject" :key="key">
          <deleteRow
            v-for="(line, id) in deleteArray"
            :key="id"
            :row="line"
            :info="{}"
            :currentTable="ocsTableInfo.tablesData[key]"
          ></deleteRow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import deleteRow from "@/commonComponents/deleteRow.vue";
import tableBottomControls from "@/commonComponents/tableBottomControls.vue";
import ocsInnerTable from "@/components/legacy/ocsInnerTable.vue";
export default {
  data() {
    return { selectedRows: {}, allSelected: false, deletedObject: {} };
  },
  components: { tableBottomControls, deleteRow, ocsInnerTable },
  props: { ocsTableInfo: Object, result: Object, search: {}, filter: {} },
  computed: {},
  methods: {
    deleteSelected() {
      this.deletedObject = this.selectedRows;
    },

    updateSelectedRows(outKeyLC, item, value) {
      this.allSelected = false;
      let selected = {};
      //console.log(selected);
      if (typeof this.selectedRows[outKeyLC] != "undefined") {
        selected = this.selectedRows[outKeyLC];
      }
      if (selected[item.n_id] && !value) {
        //   selected.splice(selected.indexOf(item.n_id), 1);
        this.$delete(selected, item.n_id);
      }

      if (typeof selected[item.n_id] == "undefined" && value) {
        this.$set(selected, item.n_id, item);
      }
      this.$set(this.selectedRows, outKeyLC, selected);
    },

    toggleSelectAll(val) {
      this.allSelected = val;
      for (let [key, table] of Object.entries(this.ocsTableInfo.tablesData)) {
        let selected = {};
        if (val) {
          table.data.forEach((element) => {
            selected[element.n_id] = element;
          });
        }
        this.$set(this.selectedRows, key, selected);
      }
    },
  },
};
</script>