<template>
  <div class="mt-7">
      <associatedOption
              :fieldAttributes='{ name: "Quick Filters",associatedOption: localOptions,value:"all","ignoreEmptyAdd":true}'
              type="combobox"
              :fieldAttrInput="{class: '',}"
              @input="setQuickFilter"

       ></associatedOption>
  </div>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  methods:{
    setQuickFilter(val){
           console.log(val);
      this.$emit("quick-filter-needle",val);

      /*this.frameworkAxiosRequest({
        method: "POST",
        url: 'serve.php?f=configuration&f2='+this.$route.query.f2+'&p='+this.$route.query.p+'&c_subType='+this.$route.query.c_subType+'',
        dataType: "json",
        data: {
           responseType: 'ajax',
           qFilterNeedle: val,
         },
      })
        .then(response => {
          this.$store.commit("resultChanged", response.data.result);
         })
        .catch(error => {
          console.log(error);
        });*/
    }
  },
  computed: {
    localOptions: function () {
      let localOptions = [];
      localOptions.push(["all","All"]);
      var caller = this;
      this.result.ocsTypes.forEach(function (value) {
        var mapped = caller.result.additional.protocolsMapping[value];
        if(typeof mapped=="undefined"){
          mapped = value;
        }
        localOptions.push([ value,mapped ]);
      });
      return localOptions;
    }
   },
  props:  ['result']
};
</script>