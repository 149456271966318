<template>
  <div>
    <div class="dataTables_wrapper tableContentParent" :id="ocsTableInfo.ocsTableId">
      <tableViewBody
        :items="currentTable['data']"
        :currentTable="currentTable"
        :result="result"
        :selectedRows="[]"
        v-on="$listeners"
      ></tableViewBody>
    </div>

    <!-- <div class="noDataInfo">{{languagePack('info_No_dat')}}</div> -->
    <!-- <div id="bottomControls" class="bottomControls">
      <div class="hrLine1"></div>
      <tableBottomControls
        :table="result.tablesInfo"
        @all-selected="toggleSelectAll(...arguments)"
        @delete-selected="deleteSelected()"
        :allSelected="allSelected"
      ></tableBottomControls>
      <div v-for="(deleteArray, key) in deletedObject" :key="key">
        <deleteRow
          v-for="(line,id) in deleteArray"
          :key="id"
          :row="line"
          :info="{}"
          :currentTable="ocsTableInfo.tablesData[key]"
        ></deleteRow>
      </div>
    </div>-->
  </div>
</template>
<script>
import tableViewBody from "@/commonComponents/tableViewBody.vue";
//import deleteRow from "@/commonComponents/deleteRow.vue";
//import tableBottomControls from "@/commonComponents/tableBottomControls.vue";
export default {
  data() {
    return { selectedRows: {}, allSelected: false, deletedObject: {} };
  },
  components: { tableViewBody /*, tableBottomControls, deleteRow*/ },
  props: { ocsTableInfo: Object, result: Object, search: {} },
  computed: {
    tablesData() {
      return this.$store.state.tablesData;
    },
    currentTable() {
      // table data is takeken from the main tablesData storage to maintain reactivity
      let table = this.ocsTableInfo;
      let returnedTable;
      // console.log(table, outKeyLC);
      if (typeof this.tablesData[table.id] == "undefined") {
        this.$store.commit("tableChanged", table);
      }
      returnedTable = this.tablesData[table.id];
      return returnedTable;
    },
  },
  methods: {
    deleteSelected() {
      this.deletedObject = this.selectedRows;
    },

    // getselectedRows(outKeyLC) {
    //   let localSelectedRows = [];
    //   if (typeof this.selectedRows[outKeyLC] != "undefined") {
    //     localSelectedRows = this.selectedRows[outKeyLC];
    //   }
    //   return Object.keys(localSelectedRows);
    // },
    // updateSelectedRows(outKeyLC, item, value) {
    //   this.allSelected = false;
    //   let selected = {};
    //   console.log(selected);
    //   if (typeof this.selectedRows[outKeyLC] != "undefined") {
    //     selected = this.selectedRows[outKeyLC];
    //   }
    //   if (selected[item.n_id] && !value) {
    //     //   selected.splice(selected.indexOf(item.n_id), 1);
    //     this.$delete(selected, item.n_id);
    //   }

    //   if (typeof selected[item.n_id] == "undefined" && value) {
    //     this.$set(selected, item.n_id, item);
    //   }
    //   this.$set(this.selectedRows, outKeyLC, selected);
    // },

    // toggleSelectAll(val) {
    //   this.allSelected = val;
    //   for (let [key, table] of Object.entries(this.ocsTableInfo.tablesData)) {
    //     let selected = {};
    //     if (val) {
    //       table.data.forEach((element) => {
    //         selected[element.n_id] = element;
    //       });
    //     }
    //     this.$set(this.selectedRows, key, selected);
    //   }
    // },
  },
};
</script>
