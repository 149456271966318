<template>
  <div class="ml-2 mt-n1">
    <div >
      <v-menu offset-y left >
        <template v-slot:activator="{ on }">
          <v-btn text x-small v-on="on" id="config_templates_menu">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <v-card min-width="1000">
            <!--<v-card-title class="subtitle-2">{{languagePack('ocs','title_new_templ')}}</v-card-title>-->
          <v-card-text>
            <span>
              <div v-if=" result.tablesInfo.addable==='true'">
                <a
                  v-if="!empty(result.ocsTypes) && (result.ocsTypes.length) == 1 && empty(result.systemTemplateArray)"
                  class="addButtNOCS special-case-1"
                  href="javascript:void(0);"
                  onclick="getTemplateNOCS('','','{$p}','{$type}','{result.ocsTypes[0]}',this,event);"
                >
                  <i
                    help-data="Click to add Connection"
                    class="fas fa-plus-square fs16"
                    style="color: #79b7e7;"
                  ></i>
                </a>

                <a
                  v-else-if="!empty(result.systemTemplateArray) || (result.ocsTypes.length) == 1 "
                  class="addButtNOCS special-case-1"
                  href="javascript:void(0);"
                  onclick="showActionButton(this,event,'');"
                >
                  <i
                    help-data="Click to add Template"
                    class="defaultActionButtPointer white fas fa-plus-square fs16"
                    style="color: #79b7e7;"
                  ></i>
                </a>

                <div class="addTemplateNOCS defaultActionChild">
                  <v-container
                    class="templateTypeBox actionContent "
                    cellpadding="1"
                    cellspacing="1"
                    style="border: 1px solid #ddd; text-align: left;border-collapse: collapse;"
                  >
                    <v-row>
                 <v-col cols="2">
                        <div class=" titleSystem h4 font-weight-bold">{{languagePack('ocs','title_new_templ')}}</div>                      </v-col>

                      <v-col  v-if="!empty(result.systemTemplateArray)">
                        <span
                          class=" titleSystem h4 font-weight-bold"
                        >{{languagePack('ocs','title_system')}}</span>
                        <span style="float: right;width:50%;">
                          <!-- <input
                            placeholder="Filter..."
                            class="searchSystemTemplate"
                            type="text"
                            width="200px;"
                            onkeyup="searchAndHighlight(this,'templateSystem li','templateTypeBox');"
                          />-->
                          <searchInput
                                  v-model="filter"
                                  :label="'Filter'"
                          ></searchInput>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col  :class="conditionalClass" >
                        <v-row
                          no-gutters
                          v-for="(catName, catKey) in result.ocsTypes"
                          :key="catKey"

                        >
                         <a
                            class="elementList"
                            :id="'elementList_'+catName"
                            href="javascript:void(0);"
                            @click="getTemplateNOCS('','',catName)"
                          >{{getMappedNameTemplate(catName)}}</a>
                        </v-row>
                      </v-col>

                      <v-col cols="10" class="templateSystem" v-if="!empty(result.systemTemplateArray)">
                        <ul class="pl-0 systemTempList" style="min-width:auto;">
                           <span v-for="(sysTempName, sysTempKey) in result.systemTemplateArray"
                             :key="sysTempKey">
                               <li no-gutters
                                   v-if="filterItem(sysTempName.c_name)"
                                   class="elementList pl-0"
                                   :id="'elementList_'+sysTempName.c_name.replaceAll(' ','_').replaceAll('|','')"
                                   href="javascript:void(0);"
                                   @click="getTemplateNOCS(sysTempName.n_id,sysTempName.n_id_key,sysTempName.c_type,{ 'mode':'system' });"
                               >{{sysTempName.c_name}}
                    <!--      <a class="elementList"
                             href="javascript:void(0);"
                             @click="getTemplateNOCS(sysTempName.n_id,sysTempName.n_id_key,sysTempName.c_type,{ 'mode':'system' });">{{sysTempName.c_name}}</a>-->
                            </li>
                           </span>
                           </ul>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </span>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <addField
      :path="path"
      :additionalAttributes="additionalAttributes"
      :showDialog="openDialog"
      @dialog-closed="dialogClosed()"
    ></addField>
  </div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import searchInput from "@/commonComponents/searchInput.vue";
export default {
  data() {
    return {
      openDialog: false,
      path: "",
      additionalAttributes: {},
      filter: "",
    };
  },
  props: ["result"],
  components: {
    addField,
    searchInput,
  },
  methods: {
    dialogClosed() {
      this.openDialog = false;
    },
    filterItem(name) {
      const regex = RegExp(this.filter, "i");
      console.log("search--->",regex.test(name));
      return regex.test(name);
    },
    getTemplateNOCS(id, key, catName, ajaxattr) {
      this.additionalAttributes = { n_id: id, n_id_key: key };
      if (id != "") {
        this.additionalAttributes.function = "getInsertedFieldsEdit";
      }
      if (typeof ajaxattr != "undefined") {
        this.additionalAttributes = {
          ...this.additionalAttributes,
          ...ajaxattr,
        };
      }
      this.path =
        this.result.ocsTemplateTableArray.tableUrl + "&c_type=" + catName;
      this.openDialog = true;
    },
  },
  computed: {
    conditionalClass: function () {
      let style = "col-10";
      if(!this.empty(this.result.systemTemplateArray)){
        style= "col-2";
      }
      return style;
    }
  }
};
</script>
<style>
    .elementList{
        width: 100%;
    }

    .elementList:hover {
        background-color: #5C9CCC;
        color:#ffffff;
        width: 100%;
    }
    .systemTempList li  {
        color: black ;
        padding: 5px ;
        text-decoration: none ;
        display: block ;
        font-weight:normal ;
    }
    .systemTempList {
        cursor: pointer;
        padding: unset;
        white-space: nowrap;
        overflow: auto;
        max-height: 25em;
        margin-block-start: unset;
        width: 100%;
    }
    .titleSystem{
       /* width: 250px;
        display: inline-block;
        height: 34px;
        vertical-align: middle;
        line-height: 34px;*/
    }
/*    .mxw100{
        max-width:100%;
    }
    .mxwFc{
        max-width:fit-content;
    }*/
</style>