<template>
  <div no-gutters class="entriesAddingButtons d-flex">
    <addField
      v-if="currentTable['addable'] === 'true'"
      @loading-dialog="updateLoadingDialog(...arguments)"
      :path="this.currentTable.tableUrl"
    >
      <primaryButton
        label="New"
        icon="mdi-plus"
        @click="openDialog = true"
        type="button"
        color="primary"
        :disabled="loadingDialog"
        :loading="loadingDialog"
        elevation="0"
        :attributesArray="{
          id: currentTable.id + '_add_new',
          'data-type': 'add_new_entry',
        }"
        shrink="smAndDown"
      ></primaryButton>
    </addField>
    <!-- <div v-if="currentTable['addable']==='true'" class="ml-2"> -->
    <!-- histroy is not addable but using this-->
    <dynamicComponent
      v-for="type in currentTable['additionalAddingButtons']"
      :key="type"
      :type="type"
      :result="result"
      :additional="{ currentTable: currentTable }"
    ></dynamicComponent>
    <!-- </div> -->
    <div
      v-if="typeof currentTable['customAdditionalHeaderButton'] != 'undefined'"
    >
      <v-row no-gutters>
        <dynamicComponent
          v-for="type in currentTable['customAdditionalHeaderButton']"
          :key="type"
          :type="type"
          :result="result"
          :additional="{ currentTable: currentTable }"
        ></dynamicComponent>
      </v-row>
    </div>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import addField from "@/commonComponents/addField.vue";

export default {
  components: {
    addField,
    primaryButton,

    dynamicComponent,
  },

  props: {
    result: Object,
  
    currentTable: Object,
 

   
  },

   data: function () {
    return {
      loadingDialog: false,
    
    };
  },

    methods: {
    updateLoadingDialog: function (loadingDialog) {
      this.loadingDialog = loadingDialog;
    },
  },
};
</script>