<template>
  <tr
    :id="rowId"
    :data-id="row[result['indexRow']]"
    v-bind="rowAttributes"
    :class="showRowStatus"
    @click="$emit('row-clicked', row[result['indexRow']], row['c_name'])"
  >
    <td
      v-if="currentTable['firstLine'] === 'true'"
      v-bind="currentTable.firstDataLineAttributes"
    >
      <v-row no-gutters style="min-width: 70px; margin-top: -7.5px">
        <v-col
          class="mr-2"
          style="min-width: 28px; max-width: 24px; padding-top: 6px"
          v-if="currentTable['selectable'] === 'true'"
        >
          <v-simple-checkbox
            v-if="row['selectable'] === 'true'"
            v-model="internalValue"
          ></v-simple-checkbox>
        </v-col>
        <tableButtons
          :row="row"
          :currentTable="currentTable"
          :result="result"
          :rowindex="rowindex"
        ></tableButtons>
      </v-row>
    </td>
    <td
      v-for="(col, header) in header"
      v-bind="getAddingNewoptionAttrs(col)"
      :key="header"
      :data-type="'row_' + header"
    >
      <tableRowFieldValue
        :row="row"
        :header="header"
        :currentTable="currentTable"
        :result="result"
        v-on="$listeners"
        :rowindex="rowindex"
      ></tableRowFieldValue>
    </td>

    <!-- <td
      style="display:none;"
      v-if="typeof currentTable['header'][header]['mask'] !='undefined' && currentTable['header'][header]['mask']=='date'"
    >{$row[header+"unix"]}</td>-->
  </tr>
</template>
<script>
import tableRowFieldValue from "@/commonComponents/tableRowFieldValue.vue";
import tableButtons from "@/commonComponents/tableButtons.vue";
export default {
  data() {
    return {};
    /* if(typeof this.row.switch_control!="undefined" && this.row.switch_control===null){
        return  {
          isActive: true
        };
      }*/
  },
  components: { tableRowFieldValue, tableButtons },
  computed: {
    showRowStatus: function () {
      return typeof this.row.singleRowAttr != "undefined"
        ? this.row.singleRowAttr.class
        : "";
    },
    rowId: function () {
      let rowId = this.currentTable.id + "_row_" + this.rowindex;
      // let rowId = this.row[this.result["indexRow"]];
      return rowId;
      // if(typeof $currentTable['rowIdFormatter'] !='undefined'){

      //     {include file=$currentTable['rowIdFormatter'] assign=rowId}
      // }
      // {/if}
    },

    rowAttributes: function () {
      return { ...this.row.singleRowAttr, ...this.currentTable.rowAttr };
    },
    internalValue: {
      get() {
        return this.selected;
      },
      set(val) {
        //this.selected = val;
        this.$emit("selected", val);
      },
    },
    header() {
      let header = {};
      for (let [key, info] of Object.entries(this.currentTable.header)) {
        if (
          !(
            typeof info.tableAttr != "undefined" &&
            typeof info.tableAttr.style != "undefined" &&
            info.tableAttr.style.indexOf("display:none;") > -1
          ) &&
          typeof info.historyInfo == "undefined"
        ) {
          header[key] = info;
        }
      }
      return header;
    },
  },
  methods: {
    getAddingNewoptionAttrs: function (col) {
      let addingNewoptionAttrs = {};
      //  addingNewoptionAttrs["data-name"] = this.header;

      if (typeof col["tableAttr"] != "undefined") {
        addingNewoptionAttrs = {
          ...col["tableAttr"],
          ...addingNewoptionAttrs,
        };
      }
      return addingNewoptionAttrs;
    },
  },
  props: ["currentTable", "row", "result", "selected", "rowindex"],
};
</script>
<!--
<style>
.orangeOutLine {
  outline: 1px solid #ffb400;
}
.redOutLine {
  outline: 1px solid red;
}
</style>-->
